<template>
  <div class="px-2 admin-applications">
    <b-row class="d-flex align-items-start d-sm-flex d-block">
      <b-col
        offset-sm-4
        sm="8"
        md="6"
        lg="6"
        class="d-flex breadcrumb-wrapper justify-content-start mb-1 mb-md-0"
      >
        <BreadCrumb :bread-crumb-items="breadCrumbItems" />
      </b-col>
    </b-row>

    <!-- pricing plan cards -->
    <b-row class="pricing-card m-2 mt-0 mt-md-5">
      <b-col
        offset-sm-2
        sm="6"
        md="12"
        lg="12"
        class="mx-auto"
      >
        <b-row class="d-flex justify-content-center mt-2">
          <span
            v-for="(application, index) in applications"
            :key="index"
          >
            <b-card
              align="center"
              class="m-1 mt-4 cursor-pointer"
              @click="goToApplicationPage(application.name)"
            >
              <div class="align-items-center">
                <div class="mt-1">
                  <img
                    :src="application.icon"
                    alt="application-icon"
                    style="max-width: 114px;"
                  >
                </div>
                <span
                  class="ml-1 font-weight-bolder group-name-class text-capitalize"
                >
                  <div>{{ application.name }}</div>
                </span>
              </div>
              <!--/ img -->
            </b-card>
          </span>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import store from '@/store'
import BreadCrumb from '@/components/common/BreadCrumb.vue'
import { BCard, BCol, BRow } from 'bootstrap-vue'
/* eslint-disable global-require */
export default {
  name: 'Applications',
  components: {
    BreadCrumb, BRow, BCol, BCard,
  },
  async beforeRouteEnter(to, from, next) {
    const isUserAdmin = store.getters['user/isUserAdmin']
    if (!isUserAdmin) {
      next('/')
    }
    next()
  },
  data() {
    return {
      applications: [],
      breadCrumbItems: [
        { name: 'Home', to: '/', active: false },
        { name: 'Applications', to: null, active: true },
      ],
    }
  },
  computed: {
    isUserAdmin() {
      return this.$store.getters['user/isUserAdmin']
    },
  },
  mounted() {
    this.applications.push({
      name: 'Simi Smart',
      icon: require('@/assets/images/simiicons/simi-logo-icon.png'),
    })
    this.applications.push({
      name: 'Simi Launcher',
      icon: require('@/assets/images/logo/simi-logo-icon.png'),
    })
  },
  methods: {
    async goToApplicationPage(applicationName) {
      if (applicationName === 'Simi Smart') {
        window.open(
          `${process.env.VUE_APP_SIMI_IOT_BASE_URL}launcher`,
          '_blank',
        )
      } else {
        window.open(
          `${process.env.VUE_APP_SIMI_LAUNCHER_BASE_URL}launcher`,
          '_blank',
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.admin-applications {
  .card {
    min-width: 400px !important;
    border-radius: 16px !important;
    @media (max-width: 921px) {
      min-width: 340px !important;
    }
    @media (max-width: 919px) {
      min-width: 300px !important;
    }
  }
  .card-body {
    padding: 1rem !important;
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
    margin-bottom: 2rem !important;
  }
  .group-name-class {
    font-size: large;
    padding-top: 10px;
    padding-bottom: 10px;
    color: black;
  }
  .breadcrumb-item.active {
    color: gray !important;
  }
}
</style>
